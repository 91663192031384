import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import DescriptionIcon from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import LastPageIcon from '@material-ui/icons/LastPage'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import PanoramaIcon from '@material-ui/icons/Panorama'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import RoomIcon from '@material-ui/icons/Room'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import TrafficIcon from '@material-ui/icons/Traffic'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/reports/oil-analysis',
    title: 'View Your Reports',
    description:
      'View and filter through all of your oil analysis sample reports.',
    background: '#1d2b3e',
    icon: <PictureAsPdfIcon />,
  },
  {
    url: '/reports/sample-status',
    title: 'Sample Status',
    description:
      'In real-time, view sample progression from receipt of the sample to the published report.',
    background: '#c18949',
    icon: <TrafficIcon />,
  },
  {
    url: '/reports/condition-summary',
    title: 'Condition Summary',
    description:
      'An overview showing the number of normal, marginal, and critical conditions by location.',
    background: '#c53333',
    icon: <RoomIcon />,
  },
  {
    url: '/reports/change-of-condition',
    title: 'Change of Condition',
    description:
      'View equipment and samples that have had a change in their machine or lubricant alarm level.',
    background: '#83590a',
    icon: <TrendingUpIcon />,
  },
  {
    url: '/reports/summary-report',
    title: 'Summary Report',
    description:
      'View the equipment and lubricant conditions, problems, and recommendations.',
    background: '#d85611',
    icon: <DescriptionIcon />,
  },
  {
    url: '/reports/common-exceptions',
    title: 'Common Exceptions',
    description:
      'View the number of alarmed samples and the type of alarm by location.',
    background: '#efcd3c',
    icon: <ErrorIcon />,
  },
  {
    url: '/reports/delinquent-samples',
    title: 'Delinquent Samples',
    description: 'View equipment that is overdue to be sampled.',
    background: '#383434',
    icon: <EventBusyIcon />,
  },
  {
    url: '/reports/schedule-compliance',
    title: 'Schedule Compliance',
    description: 'Ensure samples are being pulled when they are due.',
    background: '#d5731f',
    icon: <CalendarTodayIcon />,
  },
  {
    url: '/reports/recurring-exceptions',
    title: 'Recurring Exceptions',
    description:
      'View pieces of equipment that are exhibiting the same alarm more than once.',
    background: '#466fa8',
    icon: <SyncProblemIcon />,
  },
  {
    url: '/reports/last-sample',
    title: 'Last Sample Report',
    description:
      'View the most recent analysis report for each piece of equipment.',
    background: '#a5a5a5',
    icon: <LastPageIcon />,
  },
  {
    url: '/reports/scheduled-samples-due',
    title: 'Scheduled Samples Due',
    description: 'Ensure samples are being pulled when they are due.',
    background: '#d6d49f',
    icon: <InsertInvitationIcon />,
  },
  {
    url: '/reports/program-snapshot',
    title: 'Program Snapshot',
    description:
      'A report summarizing year-over-year sample volumes and exceptions. ',
    background: '#1c298e',
    icon: <PanoramaIcon />,
  },
  {
    url: '/reports/job-report',
    title: 'Job Reports',
    description:
      'View a list of job overview reports from the TestOil Pro Team’s visits to your facilities. ',
    background: '#fcba03',
    icon: <PlaylistAddCheckIcon />,
  },  
]

function Reports() {
  return (
    <React.Fragment>
      <SEO title="Reports" />
      <Grid container direction="row" spacing={4} xs={12}>
        <Grid item md={3} xs={12}>
          <Typography variant="h1">Reports</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              These reports will help you gather information from your oil
              analysis program to develop analytics around your lubrication
              program efforts.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4} xs={12}>
          {typeof pages !== 'undefined'
            ? pages.map(page => (
                <NavCard
                  keyValue={page.url}
                  icon={page.icon}
                  title={page.title}
                  description={page.description}
                  url={page.url}
                  background={page.background}
                ></NavCard>
              ))
            : null}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <Reports />
    </AppLayout>
  )
}
